import { delay } from 'redux-saga';
import { takeEvery, call, take, race, put, select } from 'redux-saga/effects';
import { apiManager } from 'infrastructure/api';
import browserHistory from 'infrastructure/history';

export function* logoutUser() {
  yield put({
    type: 'CLEAR_AUTH',
  });
  yield call(() => browserHistory.replace('/login'));
}

function* handleAuthEror() {
  yield logoutUser();
}

function* authorizeLoop(payload) {
  let token = payload;
  while (true) {
    const refresh = token !== null;

    if (refresh && apiManager.api) {
      const result = yield put(apiManager.api.auth.auth.refresh()); // refresh
      result.then(
        // eslint-disable-next-line no-loop-func
        res => {
          if (res.error) {
            token = null;
          } else {
            token = res.payload;
          }
        },
        // eslint-disable-next-line no-loop-func
        () => {
          token = null;
        }
      );
    }

    if (token === null) return;

    yield delay((token.expires_in - 5) * 1000);
  }
}

function* startAuthLoop(action) {
  yield race({
    signOutAction: take(
      x => x.type === 'API_SUCCESS' && x.meta.type === 'auth/auth/LOGOUT'
    ),
    authLoop: call(authorizeLoop, action.payload),
  });

  yield call(logoutUser);
}

function* checkStoredState() {
  const account = yield select(state => state.account);
  if (account && account.authenticated) {
    yield startAuthLoop({
      payload: account,
    });
  }
}

function* sagas() {
  yield takeEvery(
    x =>
      x.type === 'API_FAILURE' &&
      (x.payload.status === 401 || x.payload.status === 403),
    handleAuthEror
  );

  yield takeEvery(
    x => x.type === 'API_SUCCESS' && x.meta.type === 'auth/auth/LOGIN',
    startAuthLoop
  );

  yield checkStoredState();
}

export default sagas;
