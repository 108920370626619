import { createApiReducer } from 'infrastructure/api';

export const initialState = {};

const update = (state, payload) => ({
  ...payload,
  authenticated: true,
});

const handlers = {
  'auth/auth/EXTERNAL_PROVIDER_LOGIN': update,
  'auth/auth/LOGIN': update,
  'auth/auth/REFRESH': update,
  'auth/auth/LOGOUT': () => ({}),
};

const apiReducer = createApiReducer(handlers, initialState);

const accountReducer = (state = initialState, action) => {
  if (action.type === 'CLEAR_AUTH') {
    return {};
  }

  return apiReducer(state, action);
};

export default accountReducer;
