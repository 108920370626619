import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withApi } from 'infrastructure/api';
import { withCurrentUser } from 'infrastructure/currentUser';
import { deleteCookie } from 'infrastructure/security/cookies';
import { CSSTransition } from 'react-transition-group';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons';
import { UserAvatar } from 'components';
import TopbarSidebarButton from './TopbarSidebarButton';
import './Topbar.scss';

const enhance = compose(
  withApi,
  withCurrentUser,
  connect(null, (dispatch, props) => ({
    api: {
      auth: bindActionCreators(props.api.auth.auth, dispatch),
    },
  }))
);

const Topbar = props => {
  const {
    api,
    currentUser,
    changeMobileSidebarVisibility,
    changeSidebarVisibility,
  } = props;

  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const toggleProfileMenu = () => {
    setProfileMenuOpen(prevState => !prevState);
    document.activeElement.blur();
  };

  const logout = () => {
    deleteCookie('app');
    // eslint-disable-next-line react/destructuring-assignment
    api.auth.logout();
  };

  return (
    <div className="topbar">
      <div className="topbar-wrapper">
        <div className="topbar-left">
          <TopbarSidebarButton
            changeMobileSidebarVisibility={changeMobileSidebarVisibility}
            changeSidebarVisibility={changeSidebarVisibility}
          />
        </div>
        <div className="topbar-right">
          <Dropdown
            isOpen={profileMenuOpen}
            toggle={toggleProfileMenu}
            className="topbar-dropdown-nav"
          >
            <DropdownToggle className="topbar-dropdown-trigger">
              <UserAvatar userName={currentUser && currentUser.name} />
            </DropdownToggle>
            <CSSTransition
              in={profileMenuOpen}
              classNames="dropdown"
              timeout={200}
            >
              <DropdownMenu right>
                <DropdownItem onClick={() => logout()}>
                  <FontAwesomeIcon
                    icon={faSignOut}
                    className="highlight-icon"
                  />{' '}
                  Log Out
                </DropdownItem>
              </DropdownMenu>
            </CSSTransition>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

Topbar.propTypes = {
  api: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default enhance(Topbar);
